import { useRef } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useScheduleMembersLimit } from '@/pages/Schedule/hooks';
import { useToast } from '@/ui';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  type FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Checkbox,
} from '@/ui';
import { getErrorMessage, isGQLErrorOfType } from '@/utils/errors';
import { type TelInput, TelephoneInput } from '../TelephoneInput';
import { useAddMember } from './hooks/useAddMember';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onMemberAdded?: (memberId: string) => void;
};

type FormData = {
  name: string;
  phoneNumber: string;
  authorized: boolean;
};

export const AddMemberModal = ({ isOpen, onClose, onMemberAdded }: Props) => {
  const { addMember, isPending, error, reset: resetMutation } = useAddMember();
  const { notify } = useToast();
  const membersLimit = useScheduleMembersLimit();
  const { t } = useTranslation('members', {
    keyPrefix: 'add_member_modal',
  });

  const {
    register,
    reset: resetForm,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: true,
    defaultValues: {
      name: '',
      phoneNumber: '',
    },
  });

  const itiInstanceRef = useRef<TelInput | null>(null);

  const handleReset = () => {
    resetForm();
    resetMutation();
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const isValid = itiInstanceRef.current?.isValidNumber();
    const number = itiInstanceRef.current?.getNumber();

    if (!isValid || !number) {
      setError('phoneNumber', {});
      return;
    }

    addMember(data.name, number, {
      onSuccess: (result) => {
        notify(t('success_toast'));
        onClose();

        if (onMemberAdded && result?.addMembers && result.addMembers.length) {
          onMemberAdded(result.addMembers[0].id);
        }
      },
    });
  };

  const initialFocusRef = useRef<HTMLInputElement | null>(null);
  const { ref: nameInputRef, ...nameInput } = register('name', {
    required: true,
  });

  return (
    <Modal
      initialFocusRef={initialFocusRef}
      isCentered
      isOpen={isOpen}
      variant="primary"
      onClose={onClose}
      onCloseComplete={handleReset}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          alignItems={{ base: 'flex-start', sm: 'center' }}
          flexDir={{ base: 'column', sm: 'row' }}
          gap={{ base: 2, sm: 4 }}
        >
          {t('heading')}
          {membersLimit && membersLimit.remaining < membersLimit.limit && (
            <Text color="customgray.mid" fontSize="sm" fontWeight="normal">
              {t('limit', { remaining: membersLimit.remaining })}
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="column" gap="4">
          {!!error && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>
                {isGQLErrorOfType(error, 'SubscriberExists')
                  ? t('already_exists_error')
                  : getErrorMessage(error)}
              </AlertTitle>
            </Alert>
          )}

          <FormControl isInvalid={!!errors.name}>
            <FormLabel>{t('name.label')}</FormLabel>
            <Input
              type="text"
              ref={(el) => {
                nameInputRef(el);
                initialFocusRef.current = el;
              }}
              {...nameInput}
            />
            {errors.name && (
              <FormErrorMessage>{t('name.required')}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            isInvalid={!!errors.phoneNumber}
            sx={{ '.iti': { width: '100%' } }}
          >
            <FormLabel>{t('phone.label')}</FormLabel>

            <TelephoneInput
              itiInstanceRef={itiInstanceRef}
              {...register('phoneNumber', {
                validate: () =>
                  Boolean(itiInstanceRef.current?.isValidNumber()),
              })}
            />
            {errors.phoneNumber && (
              <FormErrorMessage>
                {errors.phoneNumber.message || t('phone.invalid')}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.authorized}>
            <FormLabel alignItems="center" display="flex" gap="2">
              <Checkbox
                {...register('authorized', {
                  required: t('authorized.required'),
                })}
              />
              {t('authorized.label')}
            </FormLabel>
            {errors.authorized && (
              <FormErrorMessage>{errors.authorized.message}</FormErrorMessage>
            )}
          </FormControl>

          <SubmitView
            display={{ base: 'flex', md: 'none' }}
            isLoading={isPending}
            mt="2"
          />
        </ModalBody>
        <ModalFooter display={{ base: 'none', md: 'flex' }}>
          <SubmitView isLoading={isPending} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SubmitView = ({
  isLoading,
  ...props
}: FlexProps & { isLoading: boolean }) => {
  const { t } = useTranslation('members', {
    keyPrefix: 'add_member_modal',
  });

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: 6, md: 0 }}
      justify="space-between"
      {...props}
    >
      <Text color="customgray.mid" fontSize="small" w={{ md: '75%' }}>
        <Trans i18nKey="privacy" t={t}>
          When you add a text message recipient, we&apos;ll send each person a
          text welcoming them to Agenda Hero. After that, only you control who
          gets a text and when. Read our{' '}
          <Link colorScheme="dark" href="/privacy" target="_blank">
            privacy policy
          </Link>
          .
        </Trans>
      </Text>
      <Button
        isLoading={isLoading}
        type="submit"
        w={{ base: '100%', md: 'auto' }}
      >
        {t('submit')}
      </Button>
    </Flex>
  );
};
