import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlinePlus } from 'react-icons/hi';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  AddMemberModal,
  type EntryModalMessage,
  MembersSelect,
} from '@/pages/Schedule/components';
import { MINIMUM_ENTRY_MESSAGE_RELATIVE_MINUTES } from '@/pages/Schedule/constants/entry';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useCreateEntryMessage,
  useDeleteEntryMessage,
  useScheduleMembers,
  useUpdateEntryMessage,
} from '@/pages/Schedule/hooks';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { getDateSummary } from '@/pages/Schedule/utils';
import {
  DeliveryTimeType,
  MessageStatusType,
  TimeUnit,
} from '@/types/gql.generated';
import {
  useToast,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
  useDisclosure,
  Tooltip,
  Alert,
} from '@/ui';
import { getErrorMessage } from '@/utils/errors';
import { SuggestMessageButton } from './components/SuggestMessageButton';
import { useSuggestTextMessage } from './hooks/useSuggestTextMessage';
import type { FormState, When } from './types';
import {
  getFormValuesFromMessage,
  toSuggestMessageInput,
  whenTimePropertiesMap,
} from './utils';

type Props = {
  entry: DecoratedEntry;
  instance?: DecoratedInstance;
  message: EntryModalMessage;
  onCancel: () => void;
  onBeforeSave?: () => Promise<void>;
  onSaveSuccess?: (message: EntryModalMessage) => void;
  onDeleteSuccess?: () => void;
};

export const EntryMessageForm = ({
  entry,
  instance,
  message,
  onCancel,
  onBeforeSave,
  onSaveSuccess,
  onDeleteSuccess,
}: Props) => {
  const { t } = useTranslation('entryMessageForm');
  const { members } = useScheduleMembers();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const { timeZone } = useScheduleContext();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const toast = useToast();

  const { suggestTextMessage, isPending: isSuggestingMessage } =
    useSuggestTextMessage();

  const {
    createEntryMessage,
    isPending: isCreatingMessage,
    error: createError,
  } = useCreateEntryMessage();

  const {
    updateEntryMessage,
    isPending: isUpdatingMessage,
    error: updateError,
  } = useUpdateEntryMessage(entry.id);

  const { deleteEntryMessage, isPending: isDeletingMessage } =
    useDeleteEntryMessage(entry.id);

  const isDeleting = useDebounceLoading(isDeletingMessage);
  const isSaving = useDebounceLoading(isCreatingMessage || isUpdatingMessage);
  const saveError = createError || updateError;

  const {
    isOpen: isAddMemberModalOpen,
    onOpen: openAddMemberModal,
    onClose: closeAddMemberModal,
  } = useDisclosure();

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit: onSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: getFormValuesFromMessage(message, timeZone),
  });

  const watchSendToEveryone = watch('sendToEveryone');
  const watchRecipients = watch('recipients');
  const watchHybridTimeZone = watch('hybridTimeZone');
  const watchDeliveryTimeType = watch('deliveryTimeType');
  const watchRelativeTimeToEntryStartUnit = watch(
    'relativeTimeToEntryStartUnit'
  );

  const handleWhenChange = (value: When) => {
    const values = whenTimePropertiesMap[value];
    for (const [key, value] of Object.entries(values)) {
      setValue(key as keyof FormState, value);
    }
  };

  const handleCancel = () => {
    clearErrors();
    onCancel();
  };

  const handleSubmit = async (formState: FormState) => {
    const updatedMessage: EntryModalMessage = {
      ...message,
      ...formState,
      instance: instance?.startDate ?? entry.recurrences[0].startDate,
      recipients: formState.recipients.map((id) => ({ id })),
    };

    await onBeforeSave?.();

    const options = {
      onSuccess: () => onSaveSuccess?.(updatedMessage),
    };

    if (updatedMessage.status === null) {
      createEntryMessage(entry, updatedMessage, options);
    } else {
      updateEntryMessage(updatedMessage, options);
    }
  };

  const handleDeleteConfirm = () => {
    deleteEntryMessage(message.id, {
      onSuccess: () => onDeleteSuccess?.(),
      onError: (err) => toast.error(getErrorMessage(err)),
    });
  };

  const handleSuggestMessage = () => {
    const input = toSuggestMessageInput(entry, instance);

    suggestTextMessage(input, {
      onSuccess: (data) => setValue('body', data.suggestTextMessage),
      onError: () => toast.error(t('what.ai_error_toast')),
    });
  };

  useEffect(() => {
    if (watchRecipients.length > 0) {
      setValue('sendToEveryone', false);
    } else {
      setValue('sendToEveryone', true);
    }
  }, [watchRecipients, setValue]);

  const canEditMessage =
    !message.status || message.status === MessageStatusType.Scheduled;

  return (
    <>
      <AddMemberModal
        isOpen={isAddMemberModalOpen}
        onClose={closeAddMemberModal}
      />

      <Flex
        as="form"
        direction="column"
        flex="1"
        gap="6"
        onSubmit={onSubmit(handleSubmit)}
      >
        {message.status !== MessageStatusType.Sent && (
          <Alert
            colorScheme="gray"
            fontSize="sm"
            lineHeight="normal"
            px="2"
            status="info"
          >
            {instance?.rule ? (
              <Box>
                {t('applies_to_instance.recurring', {
                  date: instance.isOnDay
                    ? instance.startDate.toFormat('ccc, DDD')
                    : instance.startDate.toFormat('ccc, DDD, t'),
                })}
              </Box>
            ) : (
              <Box>
                {t('applies_to_instance.all_day', {
                  date: getDateSummary(
                    entry.recurrences[0].startDate,
                    entry.recurrences[0].endDate,
                    entry.recurrences[0].isOnDay,
                    entry.recurrences[0].rule,
                    {
                      dateFormat: 'ccc, MMM d',
                      dateFormatWithYear: 'ccc, MMM d, y',
                    }
                  ),
                })}{' '}
                {!entry.recurrences[0].isOnDay &&
                  entry.recurrences[0].startDate.toFormat('ZZZZ')}
              </Box>
            )}
          </Alert>
        )}
        <FormControl>
          <FormLabel>{t('when.label')}</FormLabel>
          <HStack alignItems="flex-start" spacing="4">
            {watchDeliveryTimeType === DeliveryTimeType.Relative ? (
              <FormControl isInvalid={!!errors.relativeTimeToEntryStart}>
                <Controller
                  control={control}
                  name="relativeTimeToEntryStart"
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      isDisabled={!canEditMessage}
                      onChange={(_, valueAsNumber) => {
                        if (!isNaN(valueAsNumber)) {
                          field.onChange(valueAsNumber);
                        }
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                  rules={{
                    required: 'Required',
                    validate: {
                      minMinutes: (value) =>
                        watchRelativeTimeToEntryStartUnit ===
                          TimeUnit.Minutes &&
                        value < MINIMUM_ENTRY_MESSAGE_RELATIVE_MINUTES
                          ? t('errors.min_minutes_value')
                          : true,
                      gtZero: (value) =>
                        value < 1 || !value ? t('errors.gt_zero') : true,
                    },
                  }}
                />
                {errors.relativeTimeToEntryStart && (
                  <FormErrorMessage>
                    {errors.relativeTimeToEntryStart?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            ) : watchDeliveryTimeType === DeliveryTimeType.Hybrid ? (
              <FormControl isInvalid={!!errors.hybridDeliveryTime}>
                <Input
                  isDisabled={!canEditMessage}
                  type="time"
                  {...register('hybridDeliveryTime', {
                    required: t('errors.time_required'),
                  })}
                />
                <FormHelperText>
                  {errors.hybridDeliveryTime
                    ? errors.hybridRelativeTime?.message
                    : DateTime.local({
                        zone: watchHybridTimeZone ?? undefined,
                      }).toFormat('ZZZZZ')}
                </FormHelperText>
              </FormControl>
            ) : null}
            <FormControl>
              <Select
                isDisabled={!canEditMessage}
                {...register('when', {
                  deps: ['relativeTimeToEntryStart', 'hybridDeliveryTime'],
                  onChange: (event) =>
                    handleWhenChange(event?.target.value as When),
                })}
              >
                <option value="minutes-before">
                  {t('when.minutes_before')}
                </option>
                <option value="minutes-after">{t('when.minutes_after')}</option>
                <option value="hours-before">{t('when.hours_before')}</option>
                <option value="hours-after">{t('when.hours_after')}</option>
                <option disabled>-</option>
                <option value="day-of">{t('when.day_of')}</option>
                <option value="day-before">{t('when.day_before')}</option>
                <option value="day-after">{t('when.day_after')}</option>
              </Select>
            </FormControl>
          </HStack>

          {saveError && (
            <Alert
              fontSize="sm"
              lineHeight="1.3"
              mt="2"
              px="2"
              py="1.5"
              status="error"
            >
              {getErrorMessage(saveError)}
            </Alert>
          )}
        </FormControl>

        <FormControl>
          <FormLabel>{t('who.label')}</FormLabel>
          {!members.length ? (
            <Button
              leftIcon={<HiOutlinePlus />}
              variant="secondary"
              onClick={openAddMemberModal}
            >
              {t('add_recipient')}
            </Button>
          ) : (
            <Controller
              control={control}
              name="recipients"
              render={({ field: { onChange, value } }) => (
                <MembersSelect
                  isAddMemberModalOpen={isAddMemberModalOpen}
                  isDisabled={!canEditMessage}
                  selectedMemberIds={value}
                  sendToEveryone={watchSendToEveryone}
                  onAddClick={openAddMemberModal}
                  onSelectMember={(member) => onChange([...value, member.id])}
                  onDeselectMember={(member) => {
                    onChange(value?.filter((id) => id !== member.id));
                  }}
                  onSendToEveryoneChange={(checked) => {
                    setValue('sendToEveryone', checked);
                    if (checked) {
                      onChange([]);
                    }
                  }}
                />
              )}
            />
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.body}>
          <Flex align="center" justify="space-between" mb="2">
            <FormLabel htmlFor="message-body" mb="0">
              {t('what.label')}
            </FormLabel>
            <SuggestMessageButton
              entry={entry}
              isPending={isSuggestingMessage}
              onClick={handleSuggestMessage}
            />
          </Flex>
          <Textarea
            autoFocus={!isMobileBreakpoint}
            id="message-body"
            isDisabled={!canEditMessage || isSuggestingMessage}
            rows={3}
            {...register('body', { required: true })}
          ></Textarea>
          <FormErrorMessage>{t('errors.message_required')}</FormErrorMessage>
        </FormControl>

        <Flex align="center" gap="3">
          <Button type="button" variant="secondary" onClick={handleCancel}>
            {t('actions.cancel')}
          </Button>
          <Tooltip
            label={members.length ? undefined : t('add_recipient_tooltip')}
            shouldWrapChildren
          >
            <Button
              isDisabled={!canEditMessage || !members.length}
              isLoading={isSaving}
              type="submit"
            >
              {t('actions.save')}
            </Button>
          </Tooltip>
          {message.status === MessageStatusType.Scheduled &&
            (isConfirmingDelete ? (
              <Flex align="center" gap="2" ml="auto">
                <Button
                  isLoading={isDeleting}
                  ml="auto"
                  variant="link"
                  onClick={handleDeleteConfirm}
                >
                  {t('actions.delete_confirm')}
                </Button>
                <Button
                  colorScheme="dark"
                  variant="link"
                  onClick={() => setIsConfirmingDelete(false)}
                >
                  {t('actions.cancel')}
                </Button>
              </Flex>
            ) : (
              <Button
                ml="auto"
                variant="link"
                onClick={() => setIsConfirmingDelete(true)}
              >
                {t('actions.delete')}
              </Button>
            ))}
        </Flex>
      </Flex>
    </>
  );
};
